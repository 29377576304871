// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[8].use[1]!../../../node_modules/@jetbrains/ring-ui/components/global/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__e92 {
  display: flex;
  align-items: end;
  position: relative;

  height: var(--logo-size);
}

.icon__fa5 {
  height: 100%;
}

.icon__fa5 svg {
  margin: 0;
  height: 100%;
}

.logoSmall__aca {
  display: none;
}

@media (max-width: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["breakpoint-large"]}) {
  .collapsible__edf {
    .logoSmall__aca {
      display: unset;
    }

    .logoText__de1 {
      display: none;
    }
  }
}
`, "",{"version":3,"sources":["webpack://./src/common/ide-services-logo/ide-services-logo.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,kBAAkB;;EAElB,wBAAwB;AAC1B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,SAAS;EACT,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE;MACE,cAAc;IAChB;;IAEA;MACE,aAAa;IACf;EACF;AACF","sourcesContent":["@value breakpoint-large from '~@jetbrains/ring-ui/components/global/global.css';\n\n.wrapper {\n  display: flex;\n  align-items: end;\n  position: relative;\n\n  height: var(--logo-size);\n}\n\n.icon {\n  height: 100%;\n}\n\n.icon svg {\n  margin: 0;\n  height: 100%;\n}\n\n.logoSmall {\n  display: none;\n}\n\n@media (max-width: breakpoint-large) {\n  .collapsible {\n    .logoSmall {\n      display: unset;\n    }\n\n    .logoText {\n      display: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breakpoint-large": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["breakpoint-large"]}`,
	"wrapper": `wrapper__e92`,
	"icon": `icon__fa5`,
	"logoSmall": `logoSmall__aca`,
	"collapsible": `collapsible__edf`,
	"logoText": `logoText__de1`
};
export default ___CSS_LOADER_EXPORT___;
