import { z } from 'zod';
import { tGoogleAiFormSchema } from '../common/forms/google-ai-provider-form/google-ai-form-schema';
import { tOpenAiFormSchema } from '../common/forms/open-ai-provider-form/open-ai-form-schema';
export const tAiSettingsFormSchema = z.object({
    assignedUsersLimit: z.number().min(1).nullable().optional(),
    defaultProvider: z.string().nullable(),
    allowDataCollection: z.boolean(),
    provider: z
        .object({
        jetbrains: z
            .object({
            id: z.string()
        })
            .nullish(),
        openai: tOpenAiFormSchema.nullish(),
        google: tGoogleAiFormSchema.nullish()
    })
        .refine(providerObj => Object.values(providerObj).some(Boolean), {
        message: 'At least one provider must be configured'
    })
});
