import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Toggle from '@jetbrains/ring-ui/components/toggle/toggle';
import NavigationLink from '@common/navigation/navigation-link';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import styles from './ai-available.css';
export function AiAvailableToggleOption({ enabled, value, onChangeState, title, afterTitle }) {
    const onAndEnabled = value && enabled;
    const offAndEnabled = !value && enabled;
    // If the option is disabled, we present it as disabled to not confuse users
    // (as it will be treated as disabled).
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.toggleWrapper, children: [_jsx(Toggle, { disabled: !enabled, checked: onAndEnabled, onChange: e => onChangeState(e.target.checked), ...assignTestId('profile-ai-allow-data-collection-toggle'), children: title }), afterTitle] }), _jsxs("div", { children: [onAndEnabled && (_jsx("span", { children: "Profile users will be able to change this setting in their IDEs" })), offAndEnabled && (_jsxs("span", { children: ["Profile users will ", _jsx("strong", { children: "not" }), " be able to change this setting in their IDEs"] })), !enabled && (_jsxs("span", { ...assignTestId('enable-option-globally-notice'), children: ["Enable the option globally in", ' ', _jsx(NavigationLink, { href: "/configuration/license-activation/ai-settings", children: "AI Enterprise settings" }), ' ', "to allow profile-level changes"] }))] })] }));
}
