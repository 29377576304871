import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import classNames from 'classnames';
import { useFeedFilterProducts, useQualities } from '@common/queries/ide-feed-filters';
import { useOpenModal } from '@common/modals/hooks';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { Filter } from './filter/filter';
import { AllowedTools } from './allowed-tools/allowed-tools';
import { FilterForm } from './filter-dialog/filter-form';
import styles from './tools-filter.css';
export function ToolsFilter(props) {
    const { filters, onFiltersChange, profileId, target, disabledWithMessage, className, isFilterToolsLoading, filterTools } = props;
    const { data: allProducts, isError: isFeedFilterProductsError, isLoading: isFeedFilterProductsLoading } = useFeedFilterProducts(profileId);
    const { data: allQualities, isError: isQualitiesError, isLoading: isQualitiesLoading } = useQualities();
    const productsMap = useMemo(() => new Map(allProducts === null || allProducts === void 0 ? void 0 : allProducts.map(product => [product.id, product])), [allProducts]);
    const isError = isFeedFilterProductsError || isQualitiesError;
    const isLoading = isFeedFilterProductsLoading || isQualitiesLoading;
    const openFilterDialog = useOpenModal(FilterForm, {
        allProducts,
        allQualities,
        target,
        loading: isLoading
    }, [allProducts, allQualities, target, isLoading]);
    const filterKeys = useMemo(() => new Map(filters.map(filter => [filter, JSON.stringify(filter)])), [filters]);
    const handleRemoveFilter = useCallback((filterToRemove) => onFiltersChange(filters.filter(filter => filter !== filterToRemove)), [filters, onFiltersChange]);
    const handleEditFilter = useCallback((filterToEdit) => {
        openFilterDialog({ item: filterToEdit }).then(resultFilter => {
            if (resultFilter) {
                onFiltersChange(filters.map(filter => (filter === filterToEdit ? resultFilter : filter)));
            }
        });
    }, [openFilterDialog, onFiltersChange, filters]);
    const handleAddFilterClick = useCallback(() => {
        openFilterDialog().then(result => {
            if (result) {
                onFiltersChange([...filters, result]);
            }
        });
    }, [filters, openFilterDialog, onFiltersChange]);
    return (_jsxs("div", { className: classNames(styles.wrapper, className), ...assignTestId('tools-filter'), children: [!!filters.length && !disabledWithMessage && (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.filters, children: filters.map(filter => (_jsx(Filter, { filter: filter, onRemoveClick: handleRemoveFilter, onEditFilterClick: handleEditFilter, editable: !isLoading && !isError, loading: isLoading, productsMap: productsMap, target: target, className: styles.filter }, filterKeys.get(filter)))) }), _jsxs("p", { className: styles.info, children: [target === 'applications' && (_jsx(_Fragment, { children: "Configure the applications and versions available to profile users. Filters for settings and plugins can only be applied to the applications in this list." })), target === 'restrictions' && _jsx(_Fragment, { children: "Add a filter to restrict applications or versions" }), target === 'settings' && (_jsx(_Fragment, { children: "Add a filter to specify which applications will receive the configuration" }))] }), _jsx(AllowedTools, { className: styles.allowedTools, target: target, isFilterToolsLoading: isFilterToolsLoading, filterTools: filterTools })] })), filters.length === 0 && !disabledWithMessage && (_jsx("p", { className: styles.info, children: "Add a filter to specify which applications will receive the configuration" })), disabledWithMessage && _jsx("p", { className: styles.info, children: disabledWithMessage }), _jsx(Button, { disabled: isError || !!disabledWithMessage, className: styles.addFilterBtn, onClick: handleAddFilterClick, ...assignTestId('add-filter-btn'), children: "Add Filter" })] }));
}
