import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import auth from '@app/common/auth/auth';
import { ForbiddenPage } from './forbidden-page';
export function FeatureRestrictedPage(props) {
    const { featureName, children, featureDisabledComponent } = props;
    const isEnabled = Array.isArray(featureName)
        ? featureName.every(name => auth.getFeatures()[name])
        : Boolean(auth.getFeatures()[featureName]);
    if (!isEnabled) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return _jsx(_Fragment, { children: featureDisabledComponent !== null && featureDisabledComponent !== void 0 ? featureDisabledComponent : _jsx(ForbiddenPage, {}) });
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return _jsx(_Fragment, { children: children });
}
