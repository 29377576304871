import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import { useMachineConfig, useMachineConfigMutation } from '@app/common/queries/machine-config';
import { InlineEditableField } from '@app/common/inline-editable-field';
import { useServerConfigurationContext } from '@modules/machine-config';
export function CompanyNameEditor(props) {
    const { className } = props;
    const { data, isError, isLoading } = useMachineConfig();
    const { onConfigChanged } = useServerConfigurationContext();
    const mutation = useMachineConfigMutation(onConfigChanged);
    const handleNewValue = useCallback(async (newCompanyName) => {
        if ((data === null || data === void 0 ? void 0 : data.companyName.trim()) !== newCompanyName.trim()) {
            await mutation.mutateAsync({ companyName: newCompanyName.trim() });
        }
    }, [mutation, data]);
    if (isLoading) {
        return _jsx(Text, { children: "..." });
    }
    if (isError) {
        return _jsx(Text, { children: "-" });
    }
    return (_jsx(InlineEditableField, { className: className, currentValue: data === null || data === void 0 ? void 0 : data.companyName, onNewValue: handleNewValue, name: "companyName", autoComplete: "off" }));
}
