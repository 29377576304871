import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { ConstituentLoadingWidget } from '@modules/constituents/widget/constituent-loading-widget';
import NavigationLink from '@common/navigation/navigation-link';
import { useLvConstituentState } from '@common/queries/license-vault';
import { LicenseVaultLogo } from '../../common/license-vault-logo/license-vault-logo';
import { LvDisabledTrialActiveWidget } from './states/lv-disabled-trial-active-widget';
import { LvActiveWidget } from './states/lv-active-widget';
import { LvDisabledNoTrialWidget } from './states/lv-disabled-no-trial-widget';
export function LicenseVaultWidget(props) {
    const { constituentLicense, licenseType } = props;
    const { data: state, isLoading } = useLvConstituentState();
    const widgetBaseProps = useMemo(() => ({
        title: (_jsx(NavigationLink, { href: "/license-vault/dashboard", secondary: true, children: "License Vault" })),
        titleHint: 'License Vault',
        constituentName: 'License Vault',
        icon: _jsx(LicenseVaultLogo, {}),
        perProfileConfiguration: false
    }), []);
    if (isLoading) {
        return _jsx(ConstituentLoadingWidget, { ...widgetBaseProps });
    }
    switch (state === null || state === void 0 ? void 0 : state.status) {
        case 'enabled': {
            return (_jsx(LvActiveWidget, { ...widgetBaseProps, licenseType: licenseType, constituentLicense: constituentLicense }));
        }
        case 'disabled': {
            if (constituentLicense.trial.state === 'ACTIVE') {
                return (_jsx(LvDisabledTrialActiveWidget, { ...widgetBaseProps, licenseType: licenseType, constituentLicense: constituentLicense }));
            }
            return (_jsx(LvDisabledNoTrialWidget, { ...widgetBaseProps, licenseType: licenseType, constituentLicense: constituentLicense }));
        }
        default: {
            console.warn(`Unexpected state: ${state}`);
            return null;
        }
    }
}
