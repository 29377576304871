import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from 'react';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import Text from '@jetbrains/ring-ui/components/text/text';
import Input, { Size as InputSize } from '@jetbrains/ring-ui/components/input/input';
import Toggle from '@jetbrains/ring-ui/components/toggle/toggle';
import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline';
import { noop } from 'lodash';
import { useLicenseVaultLimits, useLicenseVaultPostpaidLimitsMutation } from '@app/common/queries/license-vault';
import HorizontalContainer from '@app/common/page-layout/horizontal-container';
import SubmitError from '@app/common/errors/submit-error';
import { ParagraphLoader } from '@app/common/loaders/paragraph-loader';
import { BasicTableView } from '@common/tables/basic-table-view';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import styles from './license-vault-limits.css';
function limitToNumber(limit) {
    return typeof limit === 'number' ? limit : 0;
}
function LicenseLimitInput({ limit, onChange }) {
    const [inputLimit, setInputLimit] = useState(typeof limit === 'number' ? limit : 0);
    return (_jsxs(HorizontalContainer, { className: styles.licenseLimitInput, children: [_jsx(Input, { placeholder: "Limit", type: "number", size: InputSize.S, min: 0, value: `${limit}`, onChange: evt => {
                    try {
                        const newLimit = Number.parseInt(evt.target.value);
                        if (newLimit > -1) {
                            setInputLimit(newLimit);
                            onChange(newLimit);
                        }
                        // eslint-disable-next-line no-empty
                    }
                    catch (err) { }
                }, onClear: () => {
                    setInputLimit(0);
                    onChange(0);
                } }), _jsx(Toggle, { checked: limit === 'UNLIMITED', onChange: evt => {
                    onChange(evt.target.checked ? 'UNLIMITED' : inputLimit);
                }, children: "Unlimited" })] }));
}
export function LicenseVaultLimits({ onCloseAttempt }) {
    const [errorResponse] = useState('');
    const { data: prepaidLicensesLimits, isLoading } = useLicenseVaultLimits('pre-paid');
    const { data: postpaidLicensesLimits } = useLicenseVaultLimits('post-paid');
    const { mutateAsync } = useLicenseVaultPostpaidLimitsMutation();
    const [postpaidLimitsMap, setPostpaidLimitsMap] = useState({});
    const tableData = useMemo(() => {
        const licenseProductsMap = (prepaidLicensesLimits || [])
            .concat(postpaidLicensesLimits || [])
            .reduce((accum, license) => {
            accum[license.id] = accum[license.id] || {
                id: license.id,
                name: license.name,
                postpaidLimit: 'UNAVAILABLE',
                prepaidLimit: 'UNAVAILABLE'
            };
            if (license.postpaid) {
                accum[license.id].postpaidLimit = license.limit;
            }
            if (!license.postpaid) {
                accum[license.id].prepaidLimit = license.limit;
            }
            return accum;
        }, {});
        return Object.keys(licenseProductsMap).map(it => licenseProductsMap[it]);
    }, [prepaidLicensesLimits, postpaidLicensesLimits]);
    const hasChanges = useMemo(() => (postpaidLicensesLimits || []).some(it => postpaidLimitsMap[it.id] !== it.limit), [postpaidLicensesLimits, postpaidLimitsMap]);
    const onSubmit = useCallback(async () => {
        if (postpaidLicensesLimits) {
            await mutateAsync(Object.keys(postpaidLimitsMap).map(key => ({ id: key, limit: postpaidLimitsMap[key] }))).catch(noop);
            onCloseAttempt(postpaidLicensesLimits);
        }
    }, [postpaidLicensesLimits, mutateAsync, onCloseAttempt, postpaidLimitsMap]);
    useEffect(() => {
        const limitsMap = postpaidLicensesLimits === null || postpaidLicensesLimits === void 0 ? void 0 : postpaidLicensesLimits.reduce((accum, license) => {
            accum[license.id] = license.limit;
            return accum;
        }, {});
        if (limitsMap) {
            setPostpaidLimitsMap(limitsMap);
        }
    }, [postpaidLicensesLimits]);
    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'name',
                title: 'License Name',
                getDataTest: () => 'limits-table-license-name',
                className: styles.licenseName
            },
            {
                id: 'prepaidLimit',
                title: 'Prepaid',
                getDataTest: () => 'limits-table-prepaid-limits',
                rightAlign: true,
                getValue(product) {
                    return _jsx(Text, { children: product.prepaidLimit === 'UNAVAILABLE' ? 0 : product.prepaidLimit });
                }
            }
        ];
        if (!(postpaidLicensesLimits === null || postpaidLicensesLimits === void 0 ? void 0 : postpaidLicensesLimits.length)) {
            return baseColumns;
        }
        const postpaidColumns = [
            {
                id: 'postpaidForm',
                title: 'Postpaid',
                getValue(product) {
                    if (product.postpaidLimit === 'UNAVAILABLE') {
                        return _jsx(Text, { info: true, children: "Unavailable" });
                    }
                    if (postpaidLimitsMap[product.id] === undefined) {
                        return _jsx(LoaderInline, {});
                    }
                    return (_jsx(LicenseLimitInput, { onChange: limit => setPostpaidLimitsMap({ ...postpaidLimitsMap, [product.id]: limit }), limit: postpaidLimitsMap[product.id] }));
                }
            },
            {
                id: 'total',
                title: 'Total',
                rightAlign: true,
                getValue(product) {
                    if (postpaidLimitsMap[product.id] === 'UNLIMITED' ||
                        product.prepaidLimit === 'UNLIMITED') {
                        return 'Unlimited';
                    }
                    const total = limitToNumber(postpaidLimitsMap[product.id]) + limitToNumber(product.prepaidLimit);
                    return _jsx(Text, { children: total === 0 ? 'Disabled' : total });
                }
            }
        ];
        return baseColumns.concat(postpaidColumns);
    }, [postpaidLicensesLimits === null || postpaidLicensesLimits === void 0 ? void 0 : postpaidLicensesLimits.length, postpaidLimitsMap]);
    return (_jsxs(Dialog, { show: true, label: "Licenses Limits", showCloseButton: true, closeButtonInside: true, autoFocusFirst: true, onEscPress: onCloseAttempt, onCloseAttempt: onCloseAttempt, contentClassName: styles.licenseLimitsDialog, children: [_jsx(Header, { children: "License Limits" }), _jsx(Content, { children: isLoading ? (_jsx(ParagraphLoader, {})) : (_jsx(BasicTableView, { columns: columns, data: tableData, selectable: false })) }), _jsxs(Panel, { children: [_jsx(SubmitError, { error: errorResponse }), _jsx(Button, { onClick: onSubmit, disabled: !hasChanges, ...assignTestId('dialog-submit-button'), primary: true, children: "Submit" }), _jsx(Button, { onClick: onCloseAttempt, ...assignTestId('ring-dialog-close-button'), children: "Cancel" })] })] }));
}
