import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { ConstituentLoadingWidget } from '@modules/constituents/widget/constituent-loading-widget';
import { IdepDisabledTrialActiveWidget } from '@modules/ide-provisioner/ide-provisioner-constituent/ide-provisioner-widget/states/idep-disabled-trial-active-widget';
import { useIdeProvisionerConstituent } from '@common/queries/ide-provisioner';
import { IdeProvisionerLogo } from '../../common/ide-provisioner-logo/ide-provisioner-logo';
import { IdepNotConfiguredWidget } from './states/idep-not-configured-widget';
import { IdepDisableNoTrialWidget } from './states/idep-disable-no-trial-widget';
import { IdepActiveWidget } from './states/idep-active-widget';
export function IdeProvisionerWidget(props) {
    const { constituentLicense, licenseType } = props;
    const { data, isLoading } = useIdeProvisionerConstituent();
    const widgetBaseProps = useMemo(() => ({
        title: 'IDE Provisioner',
        titleHint: 'IDE Provisioner',
        constituentName: 'IDE Provisioner',
        icon: _jsx(IdeProvisionerLogo, {}),
        perProfileConfiguration: true
    }), []);
    if (isLoading) {
        return _jsx(ConstituentLoadingWidget, { ...widgetBaseProps });
    }
    switch (data === null || data === void 0 ? void 0 : data.status) {
        case 'enabled':
            if (data.data.activeUsersCount > 0) {
                return (_jsx(IdepActiveWidget, { ...widgetBaseProps, licenseType: licenseType, constituentLicense: constituentLicense }));
            }
            return (_jsx(IdepNotConfiguredWidget, { ...widgetBaseProps, licenseType: licenseType, constituentLicense: constituentLicense }));
        case 'disabled':
            if (constituentLicense.trial.state === 'ACTIVE') {
                return (_jsx(IdepDisabledTrialActiveWidget, { ...widgetBaseProps, licenseType: licenseType, constituentLicense: constituentLicense }));
            }
            return (_jsx(IdepDisableNoTrialWidget, { ...widgetBaseProps, licenseType: licenseType, constituentLicense: constituentLicense }));
        default: {
            console.warn(`Unexpected state: ${data === null || data === void 0 ? void 0 : data.status}`);
            return null;
        }
    }
}
