import { createSlice, current } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { initialState } from './initial-state';
import { addOrReplace, findMainApplicationsRule, findSetting, getProfileMetadata } from './profile-reducer-helpers';
const profileSlice = createSlice({
    initialState: {
        newProfiles: initialState,
        metadata: getProfileMetadata(),
        immutableProfiles: initialState
    },
    name: 'profileReducer',
    reducers: {
        setNewProfiles: (state, action) => {
            state.newProfiles = action.payload;
            state.metadata = getProfileMetadata();
        },
        deleteSetting: (state, action) => {
            switch (action.payload.settingType) {
                case 'vmOptions':
                    state.newProfiles.settings.vmOptions = state.newProfiles.settings.vmOptions.filter(({ id }) => id !== action.payload.settingId);
                    break;
                case 'toolboxSetting':
                    state.newProfiles.settings.toolbox = state.newProfiles.settings.toolbox.filter(({ id }) => id !== action.payload.settingId);
                    break;
                case 'ide':
                    state.newProfiles.settings.ide = state.newProfiles.settings.ide.filter(({ id }) => id !== action.payload.settingId);
                    break;
                case 'autoinstalledTools':
                    state.newProfiles.settings.autoinstalledTools =
                        state.newProfiles.settings.autoinstalledTools.filter(({ id }) => id !== action.payload.settingId);
                    break;
                case 'autoInstalled':
                    state.newProfiles.plugins.autoInstalled = state.newProfiles.plugins.autoInstalled.filter(({ id }) => id !== action.payload.settingId);
                    break;
                case 'disabled':
                    state.newProfiles.plugins.disabled = state.newProfiles.plugins.disabled.filter(({ id }) => id !== action.payload.settingId);
                    break;
                case 'marketplaceSetting':
                    state.newProfiles.settings.marketplace = [];
                    break;
                default:
                    break;
            }
        },
        updateVMOptions: (state, action) => {
            state.newProfiles.settings.vmOptions = addOrReplace(state.newProfiles.settings.vmOptions, action.payload);
        },
        updateToolboxSettings: (state, action) => {
            var _a, _b;
            const patchedPayload = {
                ...action.payload,
                content: {
                    ...action.payload.content,
                    customSettings: (_b = (_a = state.newProfiles.settings.toolbox[0]) === null || _a === void 0 ? void 0 : _a.content) === null || _b === void 0 ? void 0 : _b.customSettings
                }
            };
            state.newProfiles.settings.toolbox = addOrReplace(state.newProfiles.settings.toolbox, patchedPayload);
        },
        updateAutoinstalledTools: (state, action) => {
            state.newProfiles.settings.autoinstalledTools = addOrReplace(state.newProfiles.settings.autoinstalledTools, action.payload);
        },
        updateDisabledPlugin: (state, action) => {
            state.newProfiles.plugins.disabled = addOrReplace(state.newProfiles.plugins.disabled, action.payload);
        },
        updateAutoInstalledPlugins: (state, action) => {
            state.newProfiles.plugins.autoInstalled = addOrReplace(state.newProfiles.plugins.autoInstalled, action.payload);
        },
        excludeApplication: (state, action) => {
            const excludedApplication = state.newProfiles.applications.content.find(item => item.product === action.payload);
            if (excludedApplication) {
                const excludedAppObject = {
                    ...excludedApplication,
                    excluded: true,
                    notifications: []
                };
                state.newProfiles.applications.content = state.newProfiles.applications.content.map(application => application.product === excludedApplication.product ? excludedAppObject : application);
            }
        },
        updateApplications: (state, action) => {
            state.newProfiles.applications.content = state.newProfiles.applications.content.map(application => application.product === action.payload.product ? action.payload : application);
        },
        addApplications: (state, action) => {
            state.newProfiles.applications.content = state.newProfiles.applications.content.map(application => application.product === action.payload.product
                ? { ...action.payload, excluded: false }
                : application);
        },
        updateIdeSettings: (state, action) => {
            state.newProfiles.settings.ide = addOrReplace(state.newProfiles.settings.ide, action.payload);
        },
        updateMarketplaceSettings: (state, action) => {
            if (!action.payload) {
                state.newProfiles.settings.marketplace = [];
            }
            else {
                state.newProfiles.settings.marketplace = [
                    {
                        ...(state.newProfiles.settings.marketplace[0] || {
                            id: undefined,
                            filters: [],
                            tools: [],
                            content: {
                                marketplaceAvailability: action.payload
                            }
                        }),
                        content: {
                            marketplaceAvailability: action.payload
                        }
                    }
                ];
            }
        },
        deleteNotification: (state, action) => {
            state.newProfiles.applications.rules = state.newProfiles.applications.rules.filter(rule => rule.id !== action.payload);
            state.newProfiles.restrictions.rules = state.newProfiles.restrictions.rules.filter(rule => rule.id !== action.payload);
            state.metadata.isProfileStale = true;
        },
        updateNotification: (state, action) => {
            var _a;
            const foundRule = state.newProfiles.applications.rules.find(notification => notification.id === action.payload.id);
            if (foundRule === undefined) {
                state.metadata.isProfileStale = true;
            }
            else {
                const currentState = current(foundRule);
                const isDueDateChanged = !isEqual((_a = currentState.notification) === null || _a === void 0 ? void 0 : _a.dueDate, action.payload.notification.dueDate);
                const areFiltersChanged = !isEqual(currentState.filters, action.payload.filters);
                if (areFiltersChanged || isDueDateChanged) {
                    state.metadata.isProfileStale = true;
                }
            }
            if (state.newProfiles.restrictions.rules.some(rule => rule.id === action.payload.id)) {
                state.newProfiles.restrictions.rules = state.newProfiles.restrictions.rules.map(rule => {
                    return rule.id === action.payload.id
                        ? {
                            ...rule,
                            notificationData: action.payload.notification
                        }
                        : rule;
                });
                state.newProfiles.applications.rules = state.newProfiles.applications.rules.map(rule => {
                    return rule.id === action.payload.id
                        ? {
                            ...rule,
                            notification: action.payload.notification,
                            filters: action.payload.filters
                        }
                        : rule;
                });
            }
            else {
                state.newProfiles.restrictions.rules = [
                    ...state.newProfiles.restrictions.rules,
                    {
                        id: action.payload.id,
                        notificationData: action.payload.notification,
                        tools: []
                    }
                ];
                state.newProfiles.applications.rules = [
                    ...state.newProfiles.applications.rules,
                    {
                        id: action.payload.id,
                        notification: action.payload.notification,
                        filters: action.payload.filters
                    }
                ];
            }
        },
        updateSettingFilters: (state, action) => {
            const { settingId, settingType, filters } = action.payload;
            const found = findSetting(state.newProfiles, settingId, settingType);
            if (found) {
                found.filters = filters;
            }
        },
        updateApplicationFilters: (state, action) => {
            const found = findMainApplicationsRule(state.newProfiles.applications.rules);
            if (found) {
                found.filters = action.payload.filters;
            }
            state.metadata.isProfileStale = true;
        },
        updateTools: (state, action) => {
            const { settingId, settingType, tools } = action.payload;
            const found = findSetting(state.newProfiles, settingId, settingType);
            if (found) {
                found.tools = tools;
            }
        },
        updateProfileSettings: (state, action) => {
            state.newProfiles = {
                ...state.newProfiles,
                isDefault: action.payload.isDefault,
                title: action.payload.title,
                description: action.payload.description
            };
        },
        updateCwmConstituent: (state, action) => {
            state.newProfiles.constituents.cwm = {
                ...state.newProfiles.constituents.cwm,
                ...action.payload
            };
        },
        updateAiConstituent: (state, action) => {
            state.newProfiles.constituents.ai = { ...state.newProfiles.constituents.ai, ...action.payload };
        },
        updateIDEPConstituent: (state, action) => {
            state.newProfiles.constituents.idep = {
                ...state.newProfiles.constituents.idep,
                ...action.payload
            };
        },
        resetProfileState: state => {
            state.newProfiles = initialState;
        },
        setImmutableProfilesState: (state, action) => {
            state.immutableProfiles = action.payload;
        },
        resetImmutableProfilesState: state => {
            state.immutableProfiles = initialState;
        }
    }
});
export const ProfileReducer = profileSlice.reducer;
export const ProfileActions = profileSlice.actions;
