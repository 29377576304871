import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Toggle from '@jetbrains/ring-ui/components/toggle/toggle';
import { H3 } from '@jetbrains/ring-ui/components/heading/heading';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { ProviderSelector } from '../provider-selector/provider-selector';
import { AiAvailableToggleOption } from './ai-available-toggle-option';
import { AiAvailableDataSharingInfoIcon } from './ai-available-data-sharing-info-icon';
import styles from './ai-available.css';
export function AIAvailable(props) {
    const { enabledForProfile, allowDataCollection, setAiProfileConfig, allowDataCollectionEnabled, availableProviders, defaultProvider } = props;
    return (_jsxs(_Fragment, { children: [_jsx("div", { children: _jsx(Toggle, { checked: enabledForProfile, onChange: e => setAiProfileConfig({ isEnabled: e.target.checked }), children: "Enable AI Enterprise for profile users" }) }), enabledForProfile && (_jsxs("div", { className: styles.settings, children: [_jsx(H3, { children: "Configuration" }), _jsx("div", { className: styles.settingsForm, children: _jsx("div", { ...assignTestId('profile-ai-allow-data-collection'), children: _jsx(AiAvailableToggleOption, { enabled: allowDataCollectionEnabled, value: allowDataCollection, onChangeState: state => setAiProfileConfig({ allowDataCollection: state }), title: "Allow detailed data collection", afterTitle: _jsx(AiAvailableDataSharingInfoIcon, {}) }) }) }), _jsx(ProviderSelector, { availableProviders: availableProviders, defaultProvider: defaultProvider })] }))] }));
}
