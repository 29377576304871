import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import List from '@jetbrains/ring-ui/components/list/list';
import Text from '@jetbrains/ring-ui/components/text/text';
import { noop } from 'lodash';
import { useAddProfileToUsersMutation, useInfiniteUsersQuery, useUnAssignUsersMutation } from '@app/common/queries/users';
import { useAssignGroupToProfileMutation, useGetGroupsForProfilesQuery, useInfiniteGroupsQuery, useRemoveGroupFromProfile } from '@app/common/queries/groups';
import { GroupDetails } from '@app/pages/profiles/profile-users/assign-dialog/group-details';
import { Collapsible } from '@app/common/collapsible/collapsible';
import CopiedText from '@app/common/clipboard/copied-text/copied-text';
import { ParagraphLoader } from '@app/common/loaders/paragraph-loader';
import NavigationLink from '@app/common/navigation/navigation-link';
import { assignTestId } from '@app/common/infra/tests/assign-test-id';
import { AssignButton } from './assign-button';
import styles from './assign-user-dialog.css';
export function GroupsAndUsersList({ query, profileId, profileName, userIds }) {
    const { data: assignedGroups, isLoading: isProfileGroupsQueryLoading } = useGetGroupsForProfilesQuery(profileId);
    const { data: loadedGroups, fetchNextPage: fetchNextGroupsPage, hasNextPage: hasNextGroupsPage, isFetchingNextPage: isFetchingNextGroupsPage, isError: isGroupsError, isInitialLoading: isGroupsInitialLoading } = useInfiniteGroupsQuery({ query });
    const { mutateAsync: mutateGroupsAsync } = useAssignGroupToProfileMutation();
    const { mutateAsync: unassignGroupMutation } = useRemoveGroupFromProfile();
    const handleAssignGroup = useCallback((groupId) => {
        mutateGroupsAsync({ profileId, groupId }).catch(noop);
    }, [mutateGroupsAsync, profileId]);
    const assignedGroupsIds = useMemo(() => { var _a; return (_a = assignedGroups === null || assignedGroups === void 0 ? void 0 : assignedGroups.items.map(({ id }) => id)) !== null && _a !== void 0 ? _a : []; }, [assignedGroups === null || assignedGroups === void 0 ? void 0 : assignedGroups.items]);
    const handleGroupEnterClick = useCallback((event, id) => {
        if (event.key === 'Enter') {
            handleAssignGroup(id);
        }
    }, [handleAssignGroup]);
    const handleUnassignGroup = useCallback((id) => {
        unassignGroupMutation({ profileId, groupId: id }).catch(noop);
    }, [profileId, unassignGroupMutation]);
    const groupsData = useMemo(() => {
        var _a;
        return (_a = loadedGroups === null || loadedGroups === void 0 ? void 0 : loadedGroups.pages.flatMap(({ items }) => items).map(group => ({
            key: `group-${group.id}`,
            rgItemType: List.ListProps.Type.CUSTOM,
            template: (_jsxs("div", { className: styles.listItem, onKeyDown: e => handleGroupEnterClick(e, group.id), role: "button", tabIndex: 0, children: [_jsx(Collapsible, { title: _jsx(Text, { children: group.name }), primary: true, children: _jsx(GroupDetails, { groupId: group.id }) }), _jsx(AssignButton, { assigned: assignedGroupsIds.includes(group.id), onAssign: () => handleAssignGroup(group.id), onUnAssign: () => handleUnassignGroup(group.id) })] })),
            ...assignTestId('group-row', { assigned: assignedGroupsIds.includes(group.id) })
        }))) !== null && _a !== void 0 ? _a : [];
    }, [
        assignedGroupsIds,
        handleAssignGroup,
        handleGroupEnterClick,
        handleUnassignGroup,
        loadedGroups === null || loadedGroups === void 0 ? void 0 : loadedGroups.pages
    ]);
    const [userName, setUserName] = useState('');
    const [assignedUserIds, setAssignedUserIds] = useState(userIds);
    const { data: loadedUsers, fetchNextPage: fetchNextUserPage, hasNextPage: hasNextUserPage, isFetchingNextPage: isFetchingNextUserPage, isError: isUserError, isInitialLoading: isUsersInitialLoading } = useInfiniteUsersQuery({ isActive: true, query });
    const mutation = useAddProfileToUsersMutation(profileName, [userName]);
    const { mutateAsync: unassignMutation } = useUnAssignUsersMutation();
    const handleAssignUser = useCallback((id, userName) => {
        setUserName(userName);
        mutation
            .mutateAsync({ users: [{ id }], profileId })
            .then(() => setAssignedUserIds([...assignedUserIds, id]))
            .catch(noop);
    }, [assignedUserIds, mutation, profileId]);
    const handleUserEnterClick = useCallback((event, id, userName) => {
        if (event.key === 'Enter') {
            handleAssignUser(id, userName);
        }
    }, [handleAssignUser]);
    const handleUnassignUser = useCallback((id) => {
        unassignMutation({
            profileId,
            usersList: [{ id }]
        }).catch(noop);
        setAssignedUserIds(prev => prev.filter(userId => userId !== id));
    }, [profileId, unassignMutation]);
    const usersData = useMemo(() => {
        var _a;
        return (_a = loadedUsers === null || loadedUsers === void 0 ? void 0 : loadedUsers.pages.flatMap(({ users }) => users).map(user => ({
            key: `user-${user.id}`,
            rgItemType: List.ListProps.Type.CUSTOM,
            template: (_jsxs("div", { className: styles.listItem, onKeyDown: e => handleUserEnterClick(e, user.id, user.username), role: "button", tabIndex: 0, children: [_jsxs("div", { className: styles.userInfo, children: [_jsx(NavigationLink, { target: "_blank", href: `/users/${user.id}`, className: styles.userInfoLine, children: user.username }), _jsx(Text, { size: "s", info: true, className: styles.userInfoLine, children: user.email && _jsx(CopiedText, { text: user.email, includeIcon: false }) })] }), _jsx(AssignButton, { assigned: assignedUserIds.includes(user.id), onAssign: () => handleAssignUser(user.id, user.username), onUnAssign: () => handleUnassignUser(user.id), canUnassign: user.groups.every(({ id }) => !assignedGroupsIds.includes(id)) })] })),
            ...assignTestId('user-row', { assigned: assignedUserIds.includes(user.id) })
        }))) !== null && _a !== void 0 ? _a : [];
    }, [
        loadedUsers === null || loadedUsers === void 0 ? void 0 : loadedUsers.pages,
        assignedUserIds,
        handleUserEnterClick,
        handleAssignUser,
        handleUnassignUser,
        assignedGroupsIds
    ]);
    const isInitialLoading = isGroupsInitialLoading || isUsersInitialLoading || isProfileGroupsQueryLoading;
    const groupsAndUsersList = useMemo(() => {
        if (isInitialLoading) {
            return [];
        }
        const makeTitle = (key, label) => ({
            key,
            label,
            rgItemType: List.ListProps.Type.TITLE,
            className: styles.listTitle
        });
        const makeLoadMoreButton = (key, label, isLoading, isError, fetchNext) => ({
            key,
            rgItemType: List.ListProps.Type.CUSTOM,
            template: (_jsx("div", { className: styles.listButton, children: _jsx(Button, { loader: isLoading, text: true, disabled: isError, onClick: fetchNext, children: label }) }))
        });
        return [makeTitle('groups-title', groupsData.length ? 'groups' : 'no groups found')]
            .concat(groupsData)
            .concat(hasNextGroupsPage
            ? [
                makeLoadMoreButton('load-more-groups', isGroupsError ? 'Failed to load groups' : 'Load more groups', isFetchingNextGroupsPage, isGroupsError, fetchNextGroupsPage)
            ]
            : [])
            .concat([makeTitle('users-title', usersData.length ? 'users' : 'no users found')])
            .concat(usersData)
            .concat(hasNextUserPage
            ? [
                makeLoadMoreButton('load-more-users', isGroupsError ? 'Failed to load users' : 'Load more users', isFetchingNextUserPage, isUserError, fetchNextUserPage)
            ]
            : []);
    }, [
        isInitialLoading,
        groupsData,
        hasNextGroupsPage,
        isGroupsError,
        isFetchingNextGroupsPage,
        fetchNextGroupsPage,
        usersData,
        hasNextUserPage,
        isFetchingNextUserPage,
        isUserError,
        fetchNextUserPage
    ]);
    if (isInitialLoading) {
        return _jsx(ParagraphLoader, { className: styles.loader, linesNumber: 9 });
    }
    return (_jsx("div", { className: styles.listContainer, ...assignTestId('groups-and-users-list'), children: _jsx(List, { compact: true, shortcuts: true, data: groupsAndUsersList, activateSingleItem: false, renderOptimization: false, disableScrollToActive: true }) }));
}
