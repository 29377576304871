import { useMutation, useQuery } from '@tanstack/react-query';
import alert from '@jetbrains/ring-ui/components/alert-service/alert-service';
import { formatApiError } from '@api/errors';
import { getLicenseActivationLink, licenseHistory, postLicense, readLicense, generateNewPublicKey, synchronizeLicense } from '@api/tbe-license';
import queryClient, { DEFAULT_STALE_TIME } from '@app/common/queries/query-client';
import { getMutationAlertMessages, getQueryAlertMessages } from '@app/common/queries/formatters/alerts';
export const keys = {
    all: ['tbe-license'],
    activationLink: ['tbe-license', 'activation-link'],
    license: ['tbe-license', 'license'],
    licenseHistory: (params) => ['tbe-license', 'history', { params }]
};
const activationLinkOptions = {
    queryKey: keys.activationLink,
    staleTime: DEFAULT_STALE_TIME,
    queryFn: getLicenseActivationLink,
    meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to generate activation link'))
};
export function useTbeLicenseActivationLink() {
    return useQuery({ ...activationLinkOptions });
}
export function useTbeLicense() {
    return useQuery({
        queryKey: keys.license,
        queryFn: () => readLicense(),
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load active license')),
        refetchInterval: data => ((data === null || data === void 0 ? void 0 : data.status) === 'VALIDATING' ? 3000 : false),
        refetchIntervalInBackground: true
    });
}
export function useTbeLicenseMutation() {
    return useMutation({
        mutationFn: (licenseCode) => postLicense(licenseCode),
        onSuccess: async (data) => {
            await queryClient.invalidateQueries();
            queryClient.setQueryData(keys.license, data);
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Activation failed. Please double-check your activation code'), 'License is activated!')
    });
}
export function useSynchronizeLicense() {
    return useMutation({
        mutationFn: synchronizeLicense,
        onSuccess: () => {
            queryClient.invalidateQueries();
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to validate license with Jetbrains services, please try again later'), data => {
            if (data.error) {
                alert.error(`Jetbrains services failed to validate your license. ${data.error}`);
                return null;
            }
            if (data.isLicenseChanged) {
                return 'Your license was successfully refreshed. License details will be updated in few seconds.';
            }
            return 'Your license was validated, but no updates are available';
        })
    });
}
export function useGenerateNewPublicKeyMutation() {
    return useMutation({
        mutationFn: generateNewPublicKey,
        onSuccess: async () => {
            await queryClient.invalidateQueries();
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Public key generation failed'), 'Public key is generated!')
    });
}
export function useGetLicenseEvents(params) {
    return useQuery({
        queryKey: keys.licenseHistory(params),
        queryFn: () => licenseHistory(params),
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load the license events data'))
    });
}
