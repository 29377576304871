import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import infoIcon from '@jetbrains/icons/info';
import { Size } from '@jetbrains/ring-ui/components/icon/icon';
import { AiDataSharingMessage } from '@modules/ai/common/ai-data-sharing/ai-data-sharing';
export function AiAvailableDataSharingInfoIcon() {
    const [showDataCollectionMessage, setShowDataCollectionMessage] = useState(false);
    return (_jsxs("span", { children: [_jsx(Button, { icon: infoIcon, iconSize: Size.Size14, onMouseEnter: () => setShowDataCollectionMessage(true) }), showDataCollectionMessage && (_jsx(AiDataSharingMessage, { setShowDataCollectionMessage: setShowDataCollectionMessage }))] }));
}
