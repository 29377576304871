import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { ConstituentLoadingWidget } from '@modules/constituents/widget/constituent-loading-widget';
import { useAiConstituent } from '@common/queries/ai-enterprise';
import { AiLogo } from '../../common/ai-logo/ai-logo';
import { AiActiveWidget } from './states/ai-active-widget';
import { AiDisabledNoTrialWidget } from './states/ai-disabled-no-trial-widget';
import { AiNotConfiguredWidget } from './states/ai-not-configured-widget';
import { AiDisabledTrialActiveWidget } from './states/ai-disabled-trial-active-widget';
export function AiEnterpriseWidget(props) {
    const { constituentLicense, licenseType } = props;
    const { data, isLoading } = useAiConstituent();
    const widgetBaseProps = useMemo(() => ({
        title: 'AI Enterprise',
        titleHint: 'AI Enterprise',
        constituentName: 'AI Enterprise',
        icon: _jsx(AiLogo, {}),
        perProfileConfiguration: true
    }), []);
    if (isLoading) {
        return _jsx(ConstituentLoadingWidget, { ...widgetBaseProps });
    }
    switch (data === null || data === void 0 ? void 0 : data.status) {
        case 'enabled':
            if (data.data.activeUsersCount > 0) {
                return (_jsx(AiActiveWidget, { ...widgetBaseProps, licenseType: licenseType, constituentLicense: constituentLicense }));
            }
            return (_jsx(AiNotConfiguredWidget, { ...widgetBaseProps, licenseType: licenseType, constituentLicense: constituentLicense }));
        case 'disabled':
            if (constituentLicense.trial.state === 'ACTIVE') {
                return (_jsx(AiDisabledTrialActiveWidget, { ...widgetBaseProps, licenseType: licenseType, constituentLicense: constituentLicense }));
            }
            return (_jsx(AiDisabledNoTrialWidget, { ...widgetBaseProps, licenseType: licenseType, constituentLicense: constituentLicense }));
        default: {
            console.warn(`Unexpected state: ${data === null || data === void 0 ? void 0 : data.status}`);
            return null;
        }
    }
}
